import React, { useEffect } from 'react';
import { Box, Flex, Text } from 'rebass';
import { GatsbyImage } from 'gatsby-plugin-image';
import FormBubbles from '../../components/academy/FormBubbles';
import { useHSFormsScriptLoaded } from '../../helpers/hooks';

const AcademyContactHero = ({ academyImages }) => {
  const HSFormsScriptLoaded = useHSFormsScriptLoaded();

  useEffect(() => {
    if (HSFormsScriptLoaded) {
      window.hbspt.forms.create({
        region: 'na1',
        portalId: '14573977',
        formId: 'e6ed64c0-78e0-4d17-b8ce-b6830799e46c',
        target: '#academy-contact-form',
      });
    }
  }, [HSFormsScriptLoaded]);

  return (
    <Box className='body-layout'>
      <Flex flexWrap='wrap' pt='75px' style={{ position: 'relative', zIndex: '0' }}>
        <Box width={[1, 1, 1 / 2]} px={['0px', '20px']} mt={['0px', '0px', '90px']}>
          <GatsbyImage image={academyImages.logo} alt='Nexton Academy Logo' />
          <Text
            as='h1'
            fontWeight='400'
            color='nextonOceanBlue'
            fontSize={['50px', '70px']}
            lineHeight='1'
            letterSpacing='-1.5px'
            pt='55px'
            pb='35px'>
            The door to your first job in the tech industry
          </Text>
        </Box>
        <Box width={[1, 1, 1 / 2]} px={['0px', '20px']} mb='-100px'>
          <div id='academy-contact-form' className='nexton-form' />
        </Box>
        <FormBubbles />
      </Flex>
    </Box>
  );
};

export default AcademyContactHero;
